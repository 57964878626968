<template>
  <a @click="onSubmit" :disabled="isInvalid" class="btn btn-primary">
    {{ submitting ? submittingText : normalText }}
  </a>
</template>

<script>

import {toRefs} from "vue";

export default {

  props: {
    isInvalid: {
      type: Boolean,
      default: false,
    },
    submitting: {
      type: Boolean,
      default: false,
    },
    submittingText: String,
    normalText: String,
  },

  setup(props, {emit}) {

    const {isInvalid, submitting, submittingText, normalText} = toRefs(props)

    const onSubmit = () => {
      emit('onSubmit')
    }

    return {
      onSubmit,

      isInvalid,
      submitting,
      submittingText,
      normalText,
    }

  }

}

</script>


<style scoped>

[disabled="true"] {
  pointer-events: none;
  color: #fff;
  background-color: lightgray;
  border: 1px solid lightgray;
}

[disabled="false"] {
  color: #fff;
  cursor: pointer;
}

</style>
