<template>

  <div class="input-wrap">
    <label :for="componentId">{{ label }}</label>
    <a-tree-select
      :id="componentId"
      v-model:value="selectedValue"
      tree-data-simple-mode
      style="width: 100%"
      :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
      :tree-data="treeData"
      placeholder="Lütfen seçiniz"
      @change="$emit('update:modelValue', selectedValue)"
    />
  </div>

</template>

<script>

import {v4} from 'uuid'
import {ref} from 'vue'

export default {

  props: {
    label: String,
    modelValue: {
      type: String,
    },
    items: Array, // id, name ve parent_id bilgis olan objelerin array'i
  },

  setup(props) {

    const componentId = v4()

    const selectedValue = ref(props.modelValue)

    const treeData = props.items.map(item => {
      return {
        id: item.id,
        value: item.id,
        title: item.name,
        pId: item.parent_id,
      }
    })

    return {

      componentId,

      selectedValue,

      treeData,

    }

  }

}

</script>
