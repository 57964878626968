<template>
  <div class="side">
    <div v-if="hasError">
      {{ t('someWentWrong') }}: {{ errorMessage }}
    </div>
    <div v-else>

      <div v-if="isLoading" class="loading">
        <div class="spinner"></div>
        {{ t('loading.loading') }}
      </div>
      <div v-else>

        <div class="row">

          <div v-if=" ! isMainCategoryEditing" class="col-lg-6">
            <FigTreeSelect
              :label="t('parentCategory')"
              v-model="state.parent_id"
              :items="parentCategories"
            />
          </div>

          <div class="col-lg-6">
            <div class="input-wrap">
              <label>{{ t('categoryName') }} <span class="requiredStar">*</span></label>
              <input v-model="state.name" type="text" class="form-control"/>
              <div v-if="hasNameError" class="error-msg">{{ t('required.categoryName') }}</div>
            </div>
          </div>

          <div v-if="isMainCategoryEditing" class="col-lg-6">
            <ImageInput v-model="state.image" :has-error="hasImageError" :label="t('categoryImg')" :required="true"/>
          </div>

          <div class="col-lg-8">
            <div class="input-wrap">
              <TagsInput :items="collections" v-model="state.shopify_collection_id" :label="t('categoryCollections')"/>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="input-wrap">
              <label>{{ t('rowNumber') }} <span class="requiredStar">*</span></label>
              <input class="form-control" type="number" min="0" v-model="state.row_number">
            </div>
          </div>
        </div>
        <div style="margin-top: 1.5em" class="input-wrap">
          <MySwitch v-model="state.is_active" :label="t('isCategoryStatusActive')"/>
        </div>
        <div class="btnSet right">

          <router-link :to="{name: 'Index.Page'}" class="btn btn-danger">
            {{ t('cancel') }}
          </router-link>

          <FigSubmit @onSubmit="onSubmit" :is-invalid="isFormInvalid" :submitting="submitting"
                     :submitting-text="t('saving')"
                     :normal-text="t('save')"
          />

        </div>

      </div>

    </div>

  </div>
</template>
<script>

import isEmpty from 'is-empty'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import TagsInput from '../TagsInput'
import MySwitch from '../MySwitch'
import ImageInput from '../ImageInput'
import { computed, onMounted, reactive, ref } from 'vue'
import useGlobal from '../../composables/useGlobal'
import FigSubmit from '../FigSubmit'
import FigTreeSelect from '../FigTreeSelect'
import { EditCategorySubmitRequest } from '../../service/RestApi/EditCategorySubmit'

export default {
  components: {
    FigTreeSelect,
    FigSubmit,
    MySwitch,
    TagsInput,
    ImageInput,
  },

  setup() {

    const {

      restApi,

      t,

      route,
      router,

      successNotify,
      errorNotify,

      emitRefreshCategories,

    } = useGlobal()

    const hasError = ref(false)
    const errorMessage = ref('')
    const isLoading = ref(false)
    const collections = ref([])
    const submitting = ref(false)

    const parentCategories = ref([])

    const state = reactive({
      parent_id: '',
      name: '',
      is_active: false,
      image: {
        name: '',
        data: '',
        url: '',
        has_changed: false,
      },
      row_number: '',
      shopify_collection_id: '',
    })

    const rules = {
      name: { required },
      image: { name: { required } },
    }

    const $v = useVuelidate(rules, state, { $autoDirty: true })

    const isMainCategoryEditing = computed(() => {
      return isEmpty(state.parent_id)
    })

    const hasNameError = computed(() => {
      return $v.value.name.$error
    })

    const hasImageError = computed(() => {
      if (isMainCategoryEditing.value) {
        return $v.value.image.$error
      }
      return false
    })

    const isFormInvalid = computed(() => {
      return (hasNameError.value || hasImageError.value) || submitting.value
    })

    const onSubmit = () => {

      submitting.value = true

      const request = new EditCategorySubmitRequest()

      request.setCategoryId(route.params.id)

      request.setParentId(state.parent_id)
      request.setName(state.name)
      request.setIsActive(state.is_active)
      request.setRowNumber(state.row_number)
      request.setShopifyCollectionId(state.shopify_collection_id)

      request.setImageName(state.image.name)
      request.setImageData(state.image.data)
      request.setImageHasChanged(state.image.has_changed)

      restApi.editCategorySubmit(request).then(() => {

        successNotify(t('changesSaved'), t('changesSavedDescription'))

        emitRefreshCategories()

        router.replace({
          name: 'Index.Page',
        })

      }).catch(error => {

        submitting.value = false

        errorNotify(t('changesNotSaved'), t('changesNotSavedDescription') + error.message)

      })

    }

    onMounted(() => {

      console.log(state)
      isLoading.value = true

      restApi.editCategoryShow(route.params.id).then(editShowResponse => {
        state.parent_id = editShowResponse.response.category.parent_id
        state.name = editShowResponse.response.category.name
        state.is_active = editShowResponse.response.category.is_active
        state.image.name = editShowResponse.response.category.image.name
        state.image.url = editShowResponse.response.category.image.url
        state.row_number = editShowResponse.response.category.row_number
        state.shopify_collection_id = editShowResponse.response.category.shopify_collection_id

        parentCategories.value = editShowResponse.getParentCategories()

        restApi.fetchCollections().then(response => {

          collections.value = response.getItems()
          isLoading.value = false

        })

      }).catch(error => {

        hasError.value = true

        errorMessage.value = error.toString()

      })

    })

    return {

      state,

      submitting,

      hasError,
      errorMessage,
      isLoading,
      collections,

      parentCategories,

      hasNameError,
      hasImageError,
      isFormInvalid,

      isMainCategoryEditing,

      t,

      onSubmit,

    }

  },

}
</script>

<style scoped>

.requiredStar {
  color: red;
}

</style>
